import * as React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    color: #fff;
    font-family: "Gill Sans", "Gill Sans MT", "Myriad Pro",
      "DejaVu Sans Condensed", Helvetica, Arial, "sans-serif";
    font-size: 20;
  }
`;

const Container = styled.div`
  display: flex;
  align-content: center;
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  background: rgb(68, 68, 68);
  background: radial-gradient(
    circle,
    rgba(68, 68, 68, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
`;

const Message = styled.div`
  align-self: center;
  margin: 0 auto;
`;

const HeaderH1 = styled.h1`
  font-size: 10em;
  margin-bottom: -30px;
`;

const HeaderH2 = styled.h2`
  margin-left: 10px;
`;

const HeaderH3 = styled.h2`
  margin-left: 10px;
`;

export default function NotFound() {
  return (
    <div>
      <GlobalStyle />
      <Container>
        <Message>
          <HeaderH1>404</HeaderH1>
          <HeaderH2>Page not found!</HeaderH2>
          <HeaderH3>
            This page may have been moved or be down for maintenance.
            <br />
            Please check your network connection or try again soon.
          </HeaderH3>
        </Message>
      </Container>
    </div>
  );
};
